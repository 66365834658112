@import '~react-toastify/dist/ReactToastify.css';
@import '../../../base/bootstrap-extended/include';
@import '../../../base/components/include';
@import '../../../base/core/colors/palette-variables';

.Toastify__toast-container {
	width: 380px;
	padding: 0;
	margin-bottom: 40px;
}

.Toastify__toast-icon {
  width: 0;
  height: 0;
  opacity: 0;
  display: none;
}

.Toastify__toast {
	padding: 0;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	height: auto;
	min-height: 80px;
	width: 100% !important;
  	background-color: transparent !important;

	.Toastify__toast-body {
		width: 100% !important;
		margin: 0;
		padding: 0;
	}

	.Toastify__close-button {
		opacity: 0;
		margin-top: 1px;
		margin-left: 0.5rem;
		margin: 0 !important;

		svg {
		  height: 0;
		  width: 0;
		  fill: $headings-color;
		}
		&:hover svg {
		  fill: $headings-color;
		}
		&:focus {
		  outline: 0;
		}
	}

	.custom-toaster {
		width: 100% !important;
		min-height: 100%;
    	height: 80px;

		.upper {
			width: 100%;
			height: 32px;
			background-color: #fff;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			padding-left: 15px;
      		color: rgb(110, 107, 123) !important;
      
			.title {
				flex-grow: 1;
				margin-left: 10px;
				font-family: Montserrat;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 21px;
        		color: rgb(110, 107, 123) !important;
			}

			.closeToaster {
				outline: none;
				border-radius: 50%;

				svg {
					color: #6e6b7b !important;
				}
			}
		}

		.lower {
			height: 100%;

			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 18px;
			color: #6E6B7B;
      		font-family: 'Montserrat', Helvetica, Arial, serif !important;
			padding: 6px 16px;
      		background-color: rgba($color: #fff, $alpha: 0.8);

			span.text {
				font-family: 'Montserrat', Helvetica, Arial, serif !important;
			}
		}
	}
}

// Progress Bar
.Toastify__progress-bar {
  bottom: 0;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  &.Toastify__progress-bar--default {
    background: $primary;
  }
}

// Dark Layout
.dark-layout {
  .Toastify__toast {
    background-color: $theme-dark-card-bg;
    box-shadow: 0 2px 20px 0 rgba($black, 0.3);
    .Toastify__toast-body,
    .Toastify__close-button {
      color: $theme-dark-body-color;
    }

    .toastify-body {
      color: $theme-dark-body-color;
    }

    .Toastify__close-button {
      svg {
        fill: $theme-dark-body-color;
      }
      &:hover svg {
        fill: $headings-color;
      }
    }
  }
}

// Media Queries
@media (max-width: 480px) {
  .Toastify__toast-container {
    .Toastify__toast + .Toastify__toast {
      margin-top: 1rem;
    }
  }
}
