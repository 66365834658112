$primary:#0064B7;

$tester: #fefefe;

$secondary: #03233C;
$secondary-75: mix( $secondary, white, 75 );
$secondary-50: mix( $secondary, white, 50 );
$secondary-25: mix( $secondary, white, 25 );

$danger: #f44336;
$info: #47ACFF;

$grey: #F8F8F8;
$dark-grey: #D8D8D8;